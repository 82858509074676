@import url('https://fonts.googleapis.com/css?family=Fugaz+One&display=swap');

header {
    padding: 0em 1em;
    height: 8vh;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

header button {
    font-family: 'Open Sans';
    margin-left: 1em;
    border: none;
    font-size: 1.1em;
    font-weight: 500;
}

nav a:nth-child(4) button {
    background-color: #7fffd4;
    border-radius: 5px;
    color: #021438 !important;
    font-weight: 500;
    background-image: var(--backgreen);
    background-size: 120% 120%;
    background-position: center;
    border: 2px solid transparent;
}

#header-simulator {
    color: #163e59;
    background-color: white;
}

.header-left {
    font-family: 'Fugaz One', cursive;
}

.header-link {
    justify-content: flex-start;
    align-items: center;
}

.header-logo {
    width: 2em;
    margin-right: 1em;
}

.header h4 {
    display: inline-block;
}

header .MuiPaper-root {
    background-image: var(--backpurple);
    border-left: 1px solid #555
}

.nav-responsive {
    width: 50px;
}

@media (min-width: 800px) and (max-width: 950px) {

    header button {
        font-family: 'Circular Std';
        margin-left: 0.5em;
        border: none;
        font-size: 0.8em;
    }

}

@media (min-width: 950px) and (max-width: 1100px) {

    header button {
        font-family: 'Circular Std';
        margin-left: 0.7em;
        border: none;
        font-size: 0.9em;
    }

}