.param-container-normal {
    width: 100%;
    padding: 0 2em;
}

.param-container-expanded{
    width: 100%;
    padding: 0 2em;
    grid-column-end: span 2;
}

.param-header {
    justify-content: space-between;
    align-items: center;
}

.mode-expert h6{
    color:#512072;
}

.param-name {
    margin-bottom: 2px;
    font-size: 15px;
    line-height: 19px;
}

.small-param-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 115.9%;
    letter-spacing: -0.015em;
    margin-bottom: 2px;
}

.param-info-container-hidden {
    display: none;
    opacity: 0;
}

.param-info-container-visible {
    /* background-color: var(--grey); */
    padding: 1em;
    border-radius: 1em;
    width: 100%;
}

.param-info-container-visible h6{
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 10px
}

.param-info-container-visible p{
    font-size: 0.7em;
    margin-bottom: 20px;
    width: 90%;
}
