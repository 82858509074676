@import "./icons.css";

.results-page {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}



.results-page h1 {
    color: #7fffd4;
    font-size: 3em;
    border-bottom: #7fffd4 solid 4px;
    margin-bottom: 30px;
}


.results-page h2 {
    color: #7fffd4;
    font-size: 2em;
    align-self: flex-start;
}

.results-page h3 {
    font-size: 1.5em;
    align-self: flex-start;
}

.results-page .contact-white {
    width: 65%;
}

.results-page .contact-white p {
    font-size: 1.2em;
}


/* BLINKING BTN */
#blinking-results {
    position: sticky;
    top: 2vh;
    align-self: flex-end;
    animation: blinker 1s linear infinite;
    color: var(--lightgreen);
    font-size: 4em;
    border: none;
}

/* /// HERO RESULTS */

.chapter-selection:hover span {
    color: #7fffd4;
    border-bottom: 5px solid #7fffd4;
}

.results-box {
    font-size: 1em;
    width: 80%;
    height: 70%;
    padding: 2em 3em;
    grid-template-columns: 0.8fr 0.9fr 1.3fr;
    align-items: flex-start;
    border-radius: 1em;
}

.results-left {
    margin-top: 0.3em;
    height: 95%;
    justify-content: space-between;
    align-items: flex-start;
}

.results-left h3 {
    margin-bottom: 1em;
}

.results-left h5 {
    margin-bottom: 2em;
}

#results-impacts {
    justify-content: flex-end;
}

.sim-results-head-results h5 {
    width: 100%;
    font-size: 1.5em;
    text-align: left;
}

.sim-results-head-results p {
    font-size: 1em;
}

.results-figure-results {
    background-color: var(--grey);
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    height: 3rem;
    width: 5em;
    border-radius: 0.5rem;
    margin-bottom: 0.2rem;
    margin-right: 2em;
}

.results-data {
    margin-top: 0.3em;
    width: 90%;
    height: 90%;
    justify-content: space-between;
    align-items: center;
    margin-left: 1em;
    padding-left: 1em;
}

.results-data p {
    height: 5%;
    text-align: center;
}

.results-data-sunburst {
    width: 100%;
    height: 100%;
}

.results-data-area {
    width: 100%;
    height: 100%;
}

.results-btns {
    width: 80%;
    justify-content: space-between;
}

.share-icon {
    height: 32px;
}

/* ////////////////////////// */
/* /// SYNTHESE */
/* ////////////////////////// */

#hero-article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


#res-synthese {
    flex: 1;
    margin-top: 5vh;
    width: 100%;
    align-items: center;
}

#res-synthese>div {
    width: 90%;
    padding: 20px;
    background-color: #442D59;
}

#res-synthese>div>* {
    justify-content: center;
    text-align: center;
}

#res-synthese-indicator {
    align-items: flex-start;
}

.tag-container {
    align-items: center;
    width: 30%;
}

#res-synthese>div>p {
    color: #7fffd4;
    margin: 30px 0;
    font-size: 1.5em;
    margin-left: 15%;
    width: 70%;
}

#res-synthese button {
    border: none;
    color: #34244E
}

#res-synthese-buttons>div {
    background-color: white;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    color: #34244E;
    align-items: center;
    justify-content: center;
    display: flex;
}

#res-synthese-buttons div:first-child {
    margin-right: 1em;
}


/* ////////////////////////// */
/* /// FRANCE */
/* ////////////////////////// */


/* /// PIE */
#res-emi-fr {
    width: 80%;
    align-items: center;
}

.res-emi-fr-container {
    min-height: 100vh;
    width: 100%;
    margin: 3vh 0;
}

.res-title-box {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    margin-bottom: 30px;
}

.chart-short-desc {
    font-size: 1.1em;
    margin-bottom: 20px;
    font-style: italic;
}

.res-chart-container {
    width: 100%;
}

.res-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
    height: 75vh;
}

.res-chart img {
    max-width: 98%;
}

.res-chart-infos {
    width: 45%;
    justify-content: space-around;
    padding-left: 15px
}

.res-chart-infos p {
    font-size: 0.9em;
}

.res-chart-legend {
    flex-wrap: wrap;
}

.res-chart-legend>div {
    width: 45%;
    align-items: center;
    margin-right: 5%;
}

.res-chart-legend>div div {
    padding: 5px 10px 5px 0;
}

.res-chart-legend>p {
    margin-bottom: 10px;
}

.res-chart-legend td {
    padding: 5px 10px 5px 0;
}

.legend-point {
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.res-chart-source {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 0.9em;
}


/* ////////////////////////// */
/* /// MONDE */
/* ////////////////////////// */

#res-emi-world {
    width: 80%;
    align-items: center;
}

/* ////////////////////////// */
/* /// IMPACTS */
/* ////////////////////////// */

#res-impacts {
    width: 80%;
    align-items: center;
}

#res-impacts .res-chart {
    width: 60%;
}

#res-impacts .res-chart-infos {
    width: 38%;
}


/* /// DETAIL RESULTS */
.detail-results {
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
}

.top-btn {
    margin-bottom: 4em;
}

.detail-national {
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    margin: 2em 0 3em;
    width: 90%;
}

.detail-national-main {
    width: 90%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: stretch;
    justify-items: center;
    border-radius: 1em;
    padding: 1em;
    margin-top: 1em;
}

.detail-national-box {
    width: 90%;
    height: 80%;
    align-items: center;
}

.detail-national-box h4 {
    margin-bottom: 1em;
    text-align: center;
}

.detail-impacts-temperature {
    align-items: center;
    width: 90%;
    border-radius: 1em;
    padding: 1em;
    margin-top: 1em;
}

.detail-impacts-temperature img {
    width: 70%;
    height: auto;
    margin-bottom: 3em;
}

.detail-world {
    padding: 1em;
    border-radius: 1em;
    margin: 1em 0 2em;
    width: 50%;
    height: 30em
}

.detail-world>h2 {
    text-align: center
}


.detail-parameters {
    align-items: center;
    justify-content: space-between;
    margin-top: 2em;
    width: 100%;
}

.detail-parameters-box {
    width: 90%;
    grid-template-columns: repeat(2, minmax(375px, 1fr));
    column-gap: 2em;
    row-gap: 1em;
    border-radius: 1em;
    padding: 1em;
    margin: 1em 0 2em;
}


/* FORM SAVE */

.modal-div {
    background-color: #07163A;
}

.new-or-edit {
    background-color: #07163A;
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 1em 4em;
}

.new-or-edit>*>* {
    margin: 0 5px;
}

.form-popup {
    background-color: #07163A;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0;
}

.form-popup>* {
    margin-bottom: 1em;
}

.popup-error {
    background-color: #07163A;
    text-align: center;
    padding: 1em;
    color: white;
}

.popup-error a {

    font-weight: bold;
    margin-right: 0.3em;
}


/* BLINKING BTN */
.blinking {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* 

.div-linear-chart {
    height: 100%
} */

@media (max-width: 1000px) {

    .results-page h3 {
        font-size: 1.2em;
        align-self: flex-start;
    }

    .chart-short-desc {
        font-size: 0.9em;
    }

    .res-chart-infos p {
        font-size: 0.7em;
    }

}


@media (min-height: 800px) {

    .res-emi-fr-container {
        height: 800px;
        width: 100%;
    }

    .res-chart {
        height: 600px;
    }


}



@media (min-height: 1100px) {

    #hero-article {
        height: auto;
        margin-bottom: 10vh;
    }

}